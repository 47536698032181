.section {
    border-radius: 32px;
}

.blank {
    padding: 0 6.4rem;
}

.card {
    padding: 6.4rem;
    background-color: var(--color-grey-light-1);
}