@import "../abstract/mixins";

// 11px
// 16px
// 24px

// 32px
// 48px


.text-color {
    &--primary {
        color: var(--color-primary) !important;
    }

    &--black {
        color: var(--color-black) !important;
    }

    &--white {
        color: var(--color-white) !important;
    }
}


.heading {
    color: var(--color-black);
    font-family: var(--font-primary);
    white-space: pre-line;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;

    &--primary {
        font-size: 4.8rem;
    }

    &--secondary {
        font-size: 3.2rem;
    }
}

.font {
    &--primary {
        font-family: var(--font-primary);
    }

    &--secondary {
        font-family: var(--font-secondary);
    }

    &__weight {
        &__primary {
            &--regular {
                font-weight: 400;
            }
         
            &--medium {
                font-weight: 500;
            }

            &--semi-bold {
                font-weight: 600;
            }


            &--bold {
                font-weight: 700;
            }
        }

        &__secondary {
            &--extra-bold {
                font-weight: 800;
            }
        }
    }
}

.text {
    color: var(--color-black);
    font-family: var(--font-primary);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    white-space: pre-line;

    &--small {
        font-size: 1.1rem;
    }

    &--medium {
        font-size: 1.6rem;
    }

    &--large {
        font-size: 2.4rem;
    }


}

.text-color-primary {
    color: var(--color-primary);
}

.text-shadow {
    text-shadow: var(--shadow-main);
}

.font-primary-bold {
    font-weight: 700;
}

.font-secondary-bold {
    font-weight: 700;
}

