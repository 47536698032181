.summary {
    width: 50rem;

    padding: 0 4rem;
    border-radius: 32px;
    box-shadow: var(--shadow-main);
    background-color: var(--color-grey-light-1);

    cursor: pointer;
    transition: 0.2s all;

    height: 8.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;



}
