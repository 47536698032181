@import "../abstract/mixins";

*,
*::before,
*::after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: rgba(0,0,0,0);

}

html {
    box-sizing: border-box;
    font-size: 62.5%; // 1rem = 10px | 10/16px = 62.5%
    scroll-behavior: smooth;


}

body {
    overflow: overlay;
    background-color: white;
}

.container {
    display: grid;
    //position: relative;
    //  max-width: 100vw;
    grid-template-columns: minmax(3rem, 1fr) minmax(30rem, 126rem) minmax(3rem, 1fr);
    grid-template-rows: min-content;
}

.content {
    &__full {
        grid-column: 1 / -1;
    }

    &__center {
        grid-column: 2 / 3;
    }
}

::-webkit-scrollbar {
    width: 6px;
    height: 10px;
    //  background-color: white;
}

::-webkit-scrollbar-thumb {
    background-color: var(--color-primary);
    border-radius: 100px;
}

::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
}
