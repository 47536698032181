.list {
    &__summary {
        display: flex;
        align-items: center;

        &__input {
            width: 30rem;
            margin-left: auto;
            margin-right: 2rem;
        }
    }

    &__header {
        display: flex;
        align-items: center;

        & > *:not(:last-child) {
            margin-right: 2rem;
        }

        & > *:last-child {
            margin-left: auto;
        }
    }
}
