.list {
    &__summary {
        display: flex;
        align-items: center;

        &__input {
            width: 30rem;
            margin-left: auto;
            margin-right: 2rem;
        }
    }
}
