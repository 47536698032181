.phrase {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 6rem;

    &__heading {
        margin-bottom: 6.4rem;
    }
}