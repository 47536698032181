.list {

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__input {
            width: 50rem;
        }
    }
}

.floated {
    position: fixed;
    right: 2rem;
    bottom: 3rem;

    display: flex;
    flex-direction: column;
    align-items: flex-end;

    & > *:not(:last-child) {
        margin-bottom: 2rem;
    }
}

.admin {
    &__buttons {
        display: flex;
        align-items: center;
        & > *:not(:last-child) {
            margin-right: 1rem;
        }
    }
}