.time {
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
        margin-right: .8rem;
    }

    &__icon {
        width: 1.8rem;
        height: 1.8rem;
    }
}